@import url(/src/assets/styles/styles.css);

/* CARD */
.apresentacao {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    margin-top: 2rem;
    font-family: var( --fonte-primeira);
}

/* TITULO E SUBTITULO */
.apresentacao__titulo {
    text-align: center;
    color: var(--cor-segunda);
    font-size: 32px;
    font-weight: bolder;
    margin-bottom: 0.2rem;
}

.apresentacao__subtitulo {
    color: var(--cor-terceira);
    text-align: center;
    font-size: 20px;
    font-weight: bold;
    margin-bottom: 1.5rem;
}

/* DESCRIÇÃO */
.apresentacao__descricao {
    color: var(--cor-segunda);
    font-size: 16px;
    font-weight: 500;
    margin: 0 auto 2rem auto;
    text-align: center;
    width: 70%;
    max-width: 600px;
}

.destaque_texto {
    color: var(--cor-terceira);
    font-weight: 700;
}

/* REDES */
.apresentacao_icones {
    display: flex;
    gap: 5rem;
    justify-content: center;
    align-items: center;
    width: 100%;
    margin-top: 1rem;
}

.apresentacao__icones-img {
    width: 35px;
    transition: all 0.4s ease-in-out;
}

.apresentacao__icones-img:hover{
    color: var(--cor-terceira);
    transform: scale(1.1);
}

.apresentacao__redes{
    filter: grayscale(100%);
    transition: all 0.3s ease-in-out;
    &:hover {
        filter: grayscale(0%);
    }
}


.foto__inicio {
    display: none;
}

/* RESPONSIVIDADE TABLET */
@media screen and (min-width: 1024px) {

    /* APRESENTAÇÃO */
    .apresentacao {
        display: flex;
        flex-direction: row;
        justify-content: space-evenly;
        align-items: center;
        margin: 3rem 0;
    }

    .conteudo-apresentacao {
        display: flex;
        flex-direction: column;
        align-items: center;
        width: auto;
    }
    
    /* FOTO */
    .foto__inicio {
        display: block;
        border-radius: 50px;
        width: 420px;
        margin: 0 5rem;
        box-shadow: 0 4px 8px rgba(10, 10, 0, 20);
        order: 2;
    }

    /* TITULO E SUBTITULO */
    .apresentacao__titulo {
         font-size: 74px;
         text-align: center;
    }

    .apresentacao__subtitulo {
        font-size: 54px;
        text-align: center;
    }

    /* DESCRIÇÃO */
    .apresentacao__descricao {
        font-size: 54px;
        width: 75%;
        text-align: center;
    }

    /* REDES */
    .apresentacao_icones {
        margin: 3rem 0;
        gap: 5rem;
        width: auto;
    }

    .apresentacao__icones-img {
        width: 80px;
    }
}
