.rodape {
    align-items: center;
    background-color: var(--cor-quinta);
    display: flex;
    flex-direction: row;
    justify-content: center;
    margin-top: 10rem;
    padding: 1rem 0;
    font-family: var( --fonte-primeira);
    box-shadow: 0 2px 5px rgba(1, 1, 1, 1);
}

.rodape__descricao-link {
    text-decoration: none;
    color: var(--cor-segunda);
    font-size: 20px;
    font-weight: 300;
}


/* RESPONSIVIDADE TABLET */
@media screen and (min-width: 1024px) {

}