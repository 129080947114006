/* CAIXA DOS PROJETOS */
.projetos {
    font-family: var(--fonte-primeira);
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin: 10rem 0;
}

.projetos__itens {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    background-color: var(--cor-quinta);
    border-radius: 16px;
    box-shadow: 0px 5px 4px 0px rgba(0, 0, 0, 0.60);
    width: 100%;
    max-width: 80%;
    margin: 2rem auto;
    padding: 1rem 0;
}

/* IMAGEM DO PROJETO */
.projetos_demo-img {
    width: 200px;
    height: auto;
    margin-bottom: 0.5rem;
    border-radius: 20px;
}

/* TITULO PROJETO */
.projetos__titulo {
    text-align: center;
    color: var(--cor-segunda);
    font-size: 24px;
    font-weight: 700;
    margin-top: 2rem;
}

.projetos__titulo-projeto {
    color: var(--cor-terceira);
    font-weight: 700;
    font-size: 18px;
    margin-bottom: 0.5rem;
    text-align: center;
}

/* DESCRIÇÃO PROJETO */
.projetos__descricao {
    color: var(--cor-segunda);
    font-weight: 400;
    font-size: 14px;
    width: auto;
    justify-content: center;
    text-align: center;
    margin: 0.5rem 1rem;
}

/* DESCRIÇÃO DAS TECNOLOGIAS */
.projetos__descricao-tech {
    color: var(--cor-segunda);
    font-size: 14px;
    font-weight: 300;
    margin-bottom: 0.3rem;
}

.projetos__tecnologias {
    display: flex;
    gap: 5px;
    flex-direction: row;
    margin-bottom: 1.5rem;
}

.projetos__tecnologias-img {
    width: 35px;
}

.img__javascript {
    width: 25px;
    height: 25px;
}

/* BOTOES */
.projetos__botoes {
    align-items: center;
    display: flex;
    flex-direction: row;
    gap: 0.5rem;
    justify-content: center;
}

.projetos__botao-link {
    display: inline-block;
    background-color: var(--cor-segunda);
    border: none;
    border-radius: 12px;
    padding: 0.5rem;
    text-decoration: none;
    color: var(--cor-primeira);
    transition: all 0.4s ease-in-out;
    font-size: 13px;
}

.projetos__botao-link:hover {
    background-color: var(--cor-terceira);
    color: var(--cor-primeira);
}


/* RESPONSIVIDADE TABLET */
@media screen and (min-width: 1024px) {

    .projetos {
        text-align: center;
    }

    .projetos__titulo {
        font-size: 84px;
    }

    .projetos__cards {
        display: flex;
        flex-wrap: wrap;
        justify-content: center;
    }

    .projetos__itens {
        display: grid;
        grid-template-columns: 1fr 1fr;
        grid-template-rows: repeat(3, 1fr);
        grid-area: auto;
        margin: 7rem 2rem;
        padding: 1rem 0;
    }

    .projetos_demo-img {
        grid-row: 1/4;
        grid-column: 2;
        justify-self: center;
        width: 400px;
        height: auto;
        transition: 0.3s ease-out;
    }

    .projetos__titulo-projeto {
        grid-row: 1;
        grid-column: 1;
        font-size: 44px;
        justify-self: center;
    }

    .projetos__descricao {
        grid-row: 2/3;
        grid-column: 1;
        font-size: 32px;
        width: auto;
    }

    .projetos__descricao-tech {
        grid-row: 3/5;
        grid-column: 1;
        font-size: 24px;
        justify-self: center;
    }

    .projetos__tecnologias {
        grid-row: 4;
        grid-column: 1;
        justify-content: center;
    }

    .projetos__tecnologias-img {
        width: 10%;
    }

    .img__javascript {
        width: 55px;
        height: 55px;
    }

    .projetos__botoes {
        grid-row: 4/5;
        grid-column: 2;
        gap: 5rem;
    }

    .projetos__botao-link {
        font-size: 24px;
    }
}

/* RESPONSIVIDADE DESKTOP */
@media screen and (min-width: 1400px) {

    .projetos {
        display: flex;
        flex-direction: column;
    }

    .projetos__titulo {
        font-size: 84px;
    }

    .projetos__titulo-projeto {
        font-size: 74px;
        text-align: center;
        margin: 0 2rem;
        padding: 1rem 0;
    }

    .projetos__itens {
       width: 800px;
       max-height: 700px;
       margin: 7rem 2rem;
       padding: 1.5rem 0;
    }

    .projetos__descricao {
        font-size: 42px;
        margin: 0.5rem 1.5rem;
        padding: 1rem 0;
    }

    .projetos__botoes {
        padding: 0 1rem;
    }

    .projetos__botao-link {
        width: 120px;
    }

    .projetos__descricao-tech {
        font-size: 32px;
        margin-top: 1rem;
        margin-bottom: auto;
    }

    .projetos__tecnologias {
        padding: 1.5rem 0;
    }

    .projetos__tecnologias-img {
        margin-top: 2rem;
        width: 60px;
    }

    .projetos_demo-img {
        width: 90%;
        padding: 0 1rem;
    }
}