.scroll-to-top {
    position: fixed;
    bottom: 20px;
    right: 20px;
    background-color: var(--cor-segunda);
    border-radius: 50%;
    padding: 10px;
    cursor: pointer;
    z-index: 1000;
    transition: all 0.3s ease-in-out;
  
    img {
      width: 24px;
      height: 24px;
    }
  
    &:hover {
      background-color: var(--cor-terceira);
    }
  }  